import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Swal from "sweetalert2";

const Locations = () => {
    const endpoint = "administration/locations";
    const parent = "Company";
    const [t] = useTranslation("global");
    const title = t('locationComponent.locations');
    const prefix = "location";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        country: [],
        company: []
    });
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])


    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id, action) => {
        const confirmButtonColor = action === 'active' ? 'green' : 'red';
        const confirmationMessage = action === 'active' ? t('activateAlert.activateRecord') : t('activateAlert.deactivateRecord');
        Swal.fire({
            title: confirmationMessage,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('activateAlert.confirm'),
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                const status = action === 'active' ? 1 : 0;
                const body = { action: 'updateStatus', status };
                handlerApp.setLoader(true);
                api.put(endpoint + "/" + id, body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const deleteRow = (id) => {
        Swal.fire({
            title: `${t('deleteAlert.deleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('deleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const columns = [
        {
            id: 'location_id',
            name: 'id',
            selector: row => row.location_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'country_code',
            name: t('country'),
            selector: row => row.country_code,
            sortable: true
        },
        {
            id: 'location_address',
            name: t('address'),
            selector: row => row.location_address,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    company: handlerApp.getFiltersFromData(response.data,'country_code'),
                    country: handlerApp.getFiltersFromData(response.data,'location_name')
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

  

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.location_id && item.location_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.country_code?.toLowerCase().includes(filterText.toLowerCase())
            || item.location_address?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t("locationComponent.sub_title")}</p>
                </section>
                
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("accessLog.filters")}</h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("locationComponent.location")} name="company" onChange={ e => setFilterText(e.target.value)} value="" options={filters.company}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("country")} name="country" onChange={ e => setFilterText(e.target.value)} value="" options={filters.country}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>

                    
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default Locations;